<template>
  <div>

    <b-card-code>
      <validation-observer ref="simple">

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(updateVendor)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >

                <label> التاريخ</label>
              
                <b-form-input
                      id="input-brand_name"
                      v-model="transaction.date"
                      type="text"
                      disabled
                    />
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="code"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الكود</label>
               
                    <b-form-input
                      id="input-code"
                      v-model="transaction.code"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="العملة"
                  label-for="currency_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="currency_id"
                    rules="email"
                  >
                    <b-form-input
                      id="currency_id"
                      v-model="transaction.currency.name"
                      type="currency_id"
                      disabled
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
              <b-row v-for="item in entry">
                <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الحساب"
                  label-for="account"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="account"
                    rules="required"
                  >
                    <b-form-input
                      id="account"
                      v-model="item.account.name"
                      type="text"
                      disabled
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الدائن"
                  label-for="credit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="credit"
                    rules="required"
                  >
                    <b-form-input
                      id="credit"
                      v-model="item.credit"
                      type="text"
                      disabled
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="brand"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المدين</label>
                    <b-form-input
                      id="input-brand_name"
                      v-model="item.debit"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          
          </b-form>
        </validation-observer>

      </validation-observer>
    </b-card-code>

    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted ,computed} from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  bCardTitle,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import financeStoreModule from '../finance/financeStoreModule.js'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    bCardTitle,
    financeStoreModule,
    BCardTitle,
    BCol,
    BCard,
    BCardBody,
    required,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

data(){
  return {
    entry:[],
  transaction:{},
  action:'',
  };
  
},

created() {
  this.get()
},

  methods: {
    get() {
     
     let url = `/api/v1/pending-transaction/${this.$route.params.id}`;
    //console.log(this.$route.params.id);
    this.$http.get(url).then((res) => {
   //   console.log(res.data.data);
      this.entry =res.data.data[0].before_update_entry
      this.transaction =res.data.data[0].before_update_transaction
      this.action = res.data.data.action;
        // console.log(this.entry, this.transacion, this.action);
     
    });
    // //console.log(this.personWeak);
  },


    addNewItemInItemForm() {
      this.Form.entries.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      )
    },
    removeItem(index) {
      this.Form.entries.splice(index, 1)
    },

  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const ItemData = ref([])
 //   var currentDateWithFormat = new Date().toJSON().slice(0, 10);
 let today = new Date();
 let dd = String(today.getDate()).padStart(2, '0');
 let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
 let yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;


    
    const PurchesItem = ref([])
    const SubAccountItem = ref([])
    const currencyOption = ref([])
    store.dispatch('app-finance/GetSubAccounts').then(response => {
      //console.log(',', response)
      SubAccountItem.value = response
    })
    store.dispatch('app-finance/GetCurrency').then(response => {
      //console.log('currencyOption,', response)
      currencyOption.value = response
    })
   
    const itemFormBlankItem = {
      account_id: '',
      debit: 0,
      credit: 0,

    }

    const Form = ref({

      description: '',
      currency_id: 1,
      date: '',
      entries: [
        {
          account_id: '',
          debit: 0,
          credit: 0,
        },

      ],

    })
    Form.value.date = today;
    const AllDebit = computed(() => {
      let all = 0
    Form.value.entries.forEach(el => {
        all += +el.debit
      })
    return all
    
  })
  const AllCredit = computed(() => {
      let all = 0
    Form.value.entries.forEach(el => {
        all += +el.credit
      })
    return all
    
  })
    const getAccountId = id => {
      const accountId = PurchesItem.value.find(el => el.id == id).transaction_id.account_id
      //console.log(accountId)
      store.dispatch('app-finance/getSubAccountById', accountId).then(response => {
        //console.log('currencyOption,', response)
        SubAccountItem.value = response
      })
    }
    const onSubmit = () => {
      //console.log(AllDebit)
      //console.log(AllCredit)
      if (AllDebit.value !== AllCredit.value) {
        Vue.swal({
          title: '',
          text: 'يجب ان يكون مجموع الدين يساوي مجموع الدائن',
          icon: 'error',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
     //   console.log(Form.value)
        store.dispatch('app-finance/financeTransactions', Form.value).then(response => {
          Form.value.description = ''
          Form.value.currency_id = 1
          Form.value.date = today
        

          Form.value.entries = [{ account_id: '', debit: 0, credit: 0 }]
          resetForm()
          Vue.swal({
            title: '',
            text: 'تمت أضافة  سند القيد بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
    }

   
    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer']

    return {
      today,
      dd,
      mm,
      yyyy,
      AllDebit,
      AllCredit,
      SubAccountItem,
      getAccountId,
      currencyOption,
      onSubmit,
      refFormObserver,
      PurchesItem,
      resetForm,
      getValidationState,
      ItemData,
      Form,

      itemFormBlankItem,
      paymentMethods,
    }
  },
}
</script>

    <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    </style>

    <style lang="scss" scoped>
    @import "~@core/scss/base/pages/app-invoice.scss";

    .form-item-section {
      background-color: $product-details-bg;
    }

    label {
      color: rgba(112, 192, 203, 255);
      font-weight: bold;
      font-family: "Cairo", sans-serif;
    }
    .btn-purple {
      color: white;
      background-color: #7e7a9f;
      margin-top: 25px;
    }
    span {
      font-weight: bold;
      font-family: "Cairo", sans-serif;
    }
    .form-item-action-col {
      width: 27px;
    }
    .align-left {
      left: 50px;
    }
    .but {
      top: 40px;
    }
    b-card-title {
      text-align: center;
      margin-top: 12px;
    }
    .repeater-form {
      // overflow: hidden;
      transition: 0.35s height;
    }
    .border-Primary.mb-10.col-md-12.col-xl-4 {
      margin-bottom: 21px;
    }
    .v-select {
      &.item-selector-title,
      &.payment-selector {
        background-color: #fff;

        .dark-layout & {
          background-color: unset;
        }
      }
    }

    .dark-layout {
      .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
          background-color: $theme-dark-card-bg;
        }
      }
    }
    </style>
